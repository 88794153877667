import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { Visibility } from "@mui/icons-material";
import { IconButton, styled, Typography } from "@mui/material";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getAdvertiserInvoices } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import SendInvoiceIcon from "@assets/icons/sendInvoiceIcon.svg";
import { GridColDef } from "@mui/x-data-grid";
import {
  AdvertiserInvoiceSchema,
  useAdminAdvertiserInvoiceInvoiceIdResendPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import { differenceInDays } from "date-fns";
import { ToolTip } from "@components/ToolTip";
import { SendInvoiceModal } from "@pages/advertiser/SendInvoiceModal";
import { FormProvider, useForm } from "react-hook-form";
import { formatDateForDisplay } from "@utils/formatDate";
import { enqueueSnackbar } from "notistack";
const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

export const AdvertiserInvoice = () => {
  const navigate = useNavigate();
  const { advertiserId } = useParams();
  const organizationId = useRecoilValue(organizationAtom);
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [resendInvoice, setResendInvoice] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [permissions, setPermissions] = useState({
    view: false,
    edit: false
  });

  const form = useForm({ mode: "onTouched" });

  const { mutate: resendEmail, isLoading: resendingEmail } =
    useAdminAdvertiserInvoiceInvoiceIdResendPost();

  const onView = (invoice) => navigate(`invoice/${invoice.invoiceId}`);

  const IconStyle = {
    height: "20px",
    width: "20px"
  };

  const getInvoices = (params, options) => {
    return getAdvertiserInvoices(advertiserId, params, options);
  };

  const LIST_COLUMNS: GridColDef<AdvertiserInvoiceSchema>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions?.view && (
              <ToolTip title="View Invoice" placement="right">
                <IconButton onClick={() => onView(params.row)}>
                  <Visibility style={IconStyle} />
                </IconButton>
              </ToolTip>
            )}
            {permissions?.edit && params.row.status !== "PAID" && (
              <ToolTip title="Click to Re-Send Invoice" placement="right">
                <IconButton
                  onClick={() => {
                    form.setValue(
                      "workEmail",
                      params.row?.campaign?.billingContact?.accounts?.find(
                        (a) => a.accountId === params.row?.advertiser?.accountId
                      )?.workEmail || undefined
                    );
                    form.setValue(
                      "contactId",
                      params.row?.campaign?.billingContactId
                    );
                    setResendInvoice(params.row);
                  }}
                >
                  <img
                    src={SendInvoiceIcon}
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                </IconButton>
              </ToolTip>
            )}
          </div>
        );
      }
    },

    {
      headerName: "Campaign Name",
      field: "campaignName",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return <TwoLineText>{row.campaign?.name}</TwoLineText>;
      }
    },
    {
      headerName: "Sponsorship Level",
      field: "sponsorshipLevel",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.campaign?.sponsorshipLevel?.name
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) =>
        formatCurrency(
          (row?.amountPreTax ?? 0) +
            (row?.taxAmount ?? 0) +
            (row?.feeAmount ?? 0)
        )
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.status === "NOT_SENT") return "Not Sent";
        if (row.status === "PAID") return "Paid";
        if (row.status === "UNPAID") return "Sent & Unpaid";
      }
    },
    {
      headerName: "Due On",
      field: "dueOn",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        row.sentAt ? formatDateForDisplay(new Date(row.sentAt)) : "-"
    },
    {
      headerName: "Age",
      field: "age",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.sentAt) {
          const diffDays = differenceInDays(
            new Date(),
            new Date(row.sentAt as Date)
          );
          if (diffDays > 7 && row.status === "UNPAID") {
            return (
              <Typography fontSize="12px" color="#E82C2C">
                {diffDays} days past due
              </Typography>
            );
          }
        }
        return "-";
      }
    },
    {
      headerName: "Sent On",
      field: "sentOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) =>
        row.sentAt ? formatDateForDisplay(new Date(row.sentAt)) : "-"
    },
    {
      headerName: "Paid On",
      field: "paidOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) =>
        row.paidAt ? formatDateForDisplay(new Date(row.paidAt)) : "-"
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("live-streaming.advertisers", "VIEW");
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      const permission = {
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "Not Sent",
        value: "NOT_SENT"
      },
      {
        label: "Paid",
        value: "PAID"
      },
      {
        label: "Sent & Unpaid",
        value: "UNPAID"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Invoices"
          useGet={getInvoices}
          columns={LIST_COLUMNS}
          getRowId={(row) => row.invoiceId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
          hideFooter
          getRowHeight={() => "auto"}
        />
        {resendInvoice && (
          <FormProvider {...form}>
            <SendInvoiceModal
              onClose={() => setResendInvoice(null)}
              title="Re-Send Invoice"
              type="RESEND"
              onSave={async (contact) => {
                await resendEmail(
                  {
                    advertiserId: advertiserId!,
                    invoiceId: resendInvoice.invoiceId!,
                    data: {
                      contactId: contact.contactId!,
                      workEmail: contact.email!
                    }
                  },
                  {
                    onSuccess: () => {
                      enqueueSnackbar("Invoice sent successfully", {
                        variant: "success"
                      });
                      setResendInvoice(null);
                    },
                    onError: () => {
                      enqueueSnackbar("Failed to send invoice", {
                        variant: "error"
                      });
                      setResendInvoice(null);
                    }
                  }
                );
              }}
              advertiserId={advertiserId!}
              contactSelected={{
                label:
                  resendInvoice.campaign?.billingContact?.firstName +
                  " " +
                  resendInvoice.campaign?.billingContact?.lastName,
                value: resendInvoice.campaign?.billingContactId ?? "",
                email: resendInvoice?.campaign?.billingContact?.accounts?.find(
                  (a) => a.accountId === resendInvoice.advertiser?.accountId
                )?.workEmail
              }}
              isLoading={resendingEmail}
            />
          </FormProvider>
        )}
      </Loader>
    </>
  );
};
