/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import {
  AD_LAYOUT_OPTIONS,
  AD_PLACEMENT_OPTIONS,
  CALL_TO_ACTION_OPTIONS
} from "@utils/constants";
import { useEffect, useState } from "react";
import { FormInput } from "@components/FormInput";
import { IconButton, Typography } from "@mui/material";
import { LivePreview } from "@components/LivePreview";
import { styled } from "@mui/material";
import { RenderTableView } from "@components/RenderTableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  useAdminAdvertiserAdvertisementIdGet,
  useAdminAdvertiserAdvertisementIdDelete,
  ModelMedia
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { ReplyIcon } from "@components/Icons";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import ReactPlayer from "react-player";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";

const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column !important"
  }
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "50% !important",
  [theme.breakpoints.down("xl")]: {
    width: "100% !important"
  }
}));
const StyledLivePreview = styled(Grid)(({ theme }) => ({
  width: "500px !important",
  marginLeft: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "75% !important"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "100% !important"
  }
}));

export const AdvertisementView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useRecoilValue(organizationAtom);
  const { advertiserId } = useParams();
  const { advertisementId } = useParams();
  const [adplacement, setAdPlacement] = useState("");
  const [adLayout, setAdLayout] = useState("");
  const loggedInUser = useRecoilValue(profileAtom)?.personId;
  const [permissions, setPermissions] = useState({
    edit: false,
    delete: false
  });
  const [advertisementToDelete, setAdvertisementToDelete] =
    useState<boolean>(false);
  const [adDetails, setAdDetails] = useState<{
    title: string;
    description: string;
    media: File | ModelMedia | null | undefined;
    icon: string | undefined;
    redirect: string | undefined;
    linkText: string;
    actionButton: string | undefined;
    businessName: string | undefined;
  }>({
    title: "",
    description: "",
    media: undefined,
    icon: undefined,
    redirect: undefined,
    linkText: "",
    actionButton: undefined,
    businessName: undefined
  });
  const { data: advertisement, isLoading: advertisementLoading } =
    useAdminAdvertiserAdvertisementIdGet(advertiserId!, advertisementId!);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminAdvertiserAdvertisementIdDelete();

  const onConfirmDelete = async () => {
    if (!advertisementId) return;
    try {
      await deleteAsync({
        advertisementId: advertisementId!,
        advertiserId: advertiserId!
      });
      enqueueSnackbar("Advertisement deleted successfully", {
        variant: "success"
      });
      navigate(`/advertisers/${advertiserId}`);
      setAdvertisementToDelete(false);
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete advertisement.", {
        variant: "error"
      });
      setAdvertisementToDelete(false);
    }
  };

  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];
  const { control, reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      placement: "",
      layout: "",
      status: "",
      duration: "",
      file: undefined,
      title: "",
      description: "",
      redirect: "",
      icon: undefined,
      linkText: "",
      actionButton: "",
      fileName: ""
    }
  });
  const dateFormat = (date) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: true
    };
    //@ts-ignore
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  useEffect(() => {
    if (advertisement?.data) {
      reset({
        placement: advertisement.data.placement,
        status: advertisement.data.status,
        layout: advertisement.data.layout || "",
        duration: String(advertisement.data.duration) || "",
        file: undefined,
        title: advertisement.data.title || "",
        description: advertisement.data.description || "",
        redirect: advertisement.data.redirectLink || "",
        icon: undefined,
        linkText: advertisement.data.linkText || "",
        actionButton: advertisement.data.actionButton || "",
        fileName:
          (advertisement.data.media?.metadata as { fileName?: string })
            ?.fileName || ""
      });
      setAdDetails((details) => ({
        ...details,
        title: advertisement.data.title || "",
        description: advertisement.data.description || "",
        media:
          advertisement.data.layout == "Link" &&
          advertisement.data?.metadata?.image
            ? advertisement.data?.metadata?.image
            : advertisement.data.media?.baseUrl
              ? advertisement.data.media.baseUrl + advertisement.data.media.path
              : "",
        logo: advertisement.data.advertiser?.logo?.baseUrl
          ? advertisement.data.advertiser?.logo?.baseUrl +
            advertisement.data.advertiser?.logo?.path
          : "",
        redirect: advertisement.data.redirectLink || "",
        actionButton: advertisement.data.actionButton || "",
        businessName: advertisement.data.advertiser?.businessName || "",
        linkText: advertisement.data.linkText || ""
      }));
      setAdPlacement(advertisement.data.placement as string);
      setAdLayout(advertisement.data.layout || "");
    }
  }, [advertisement]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      const del = await checkPermission("live-streaming.advertisers", "DELETE");
      setPermissions({
        edit: edit,
        delete: del
      });
    };
    fetchPermissions();
  }, []);

  const ADVERTISEMENT_FEEDBACK_COLUMNS: GridColDef[] = [
    {
      headerName: "Advertisement",
      field: "advertisement",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: () => (
        <>
          {(advertisement?.data.media ||
            advertisement?.data.metadata?.image) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              {advertisement?.data.placement === "INSTREAM" ? (
                <ReactPlayer
                  width="140px"
                  height="100px"
                  style={{ marginRight: "1rem" }}
                  url={
                    advertisement?.data?.media?.baseUrl &&
                    advertisement?.data.media.baseUrl +
                      advertisement?.data.media.path
                  }
                />
              ) : advertisement?.data.media?.baseUrl &&
                advertisement?.data.media.type == "VIDEO" ? (
                <ReactPlayer
                  width="140px"
                  height="100px"
                  style={{ marginRight: "1rem" }}
                  url={
                    advertisement?.data.media.baseUrl +
                    advertisement?.data.media.path
                  }
                />
              ) : advertisement?.data.layout == "Link" ||
                advertisement?.data.media ? (
                <img
                  src={
                    advertisement?.data.layout != "Link"
                      ? advertisement?.data.media?.baseUrl &&
                        advertisement?.data?.media?.baseUrl +
                          advertisement?.data?.media?.path
                      : advertisement?.data.layout == "Link" &&
                          advertisement?.data.metadata?.image
                        ? advertisement?.data.metadata.image
                        : ""
                  }
                  style={{
                    width: "140px",
                    height: "100px",
                    marginRight: "1rem"
                  }}
                  alt="Media"
                />
              ) : null}
            </div>
          )}
        </>
      )
    },
    {
      headerName: "Date / Time",
      field: "createdAt",
      minWidth: 250,
      flex: 1,
      valueFormatter: (params) => dateFormat(params.value)
    },
    {
      headerName: "Sent By",
      field: "sender",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        if (!params.row.isOrgFeedback)
          return (
            `${
              organizationId
                ? params.row.contactSentBy?.firstName
                : params.row.sentBy?.firstName
            } ${
              organizationId
                ? params.row.contactSentBy?.lastName
                : params.row.sentBy?.lastName
            }` || ""
          );
        return (
          `${params.row.orgSentBy?.firstName} ${params.row.orgSentBy?.lastName}` ||
          ""
        );
      }
    },
    {
      headerName: "Feedback",
      field: "feedback",
      minWidth: 150,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Is Approved",
      field: "isApproved",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueFormatter: (params) => (params.value ? "Yes" : "No")
    }
  ];
  const reply = (feedbackId: string) => {
    navigate(
      `/advertisers/${advertiserId}/advertisement/${advertisementId}/${feedbackId}/reply`
    );
  };
  return (
    <Loader isLoading={advertisementLoading}>
      <Container>
        <Toolbar
          title="View Advertisement"
          {...(permissions.edit && {
            editBtnClick: () => {
              navigate("edit");
            }
          })}
          {...(permissions.delete && {
            deleteBtnClick: () => {
              setAdvertisementToDelete(true);
            }
          })}
          backBtnClick={() => window.history.back()}
        />
        <StyledForm style={{ flexDirection: "row" }}>
          <StyledGrid
            data-testid="advertisement-view-form"
            container
            direction={"column"}
          >
            <Grid xs={12} data-testid="advertisement-placment">
              <FormSelect
                control={control}
                name="placement"
                label="Ad Placement"
                options={AD_PLACEMENT_OPTIONS}
                required={true}
                disabled={true}
              />
            </Grid>
            {adplacement != "INSTREAM" && (
              <Grid
                xs={12}
                data-testid="advertisement-layout"
                marginTop={"25px"}
              >
                <FormSelect
                  control={control}
                  name="layout"
                  label="Ad Layout"
                  required={true}
                  disabled={true}
                  options={
                    AD_LAYOUT_OPTIONS.filter((option) =>
                      option.adType.includes(adplacement)
                    ) || []
                  }
                />
              </Grid>
            )}
            {adplacement == "INSTREAM" && (
              <Grid
                xs={12}
                data-testid="advertisement-duration"
                marginTop={"25px"}
              >
                <FormSelect
                  label="Duration"
                  control={control}
                  name="duration"
                  disabled={true}
                  options={[
                    { label: "6 seconds", value: "6" },
                    { label: "15 seconds", value: "15" },
                    { label: "30 seconds", value: "30" }
                  ]}
                />
              </Grid>
            )}
            {adplacement != "" &&
              (adLayout != "" || adplacement == "INSTREAM") &&
              adLayout != "Link" &&
              adLayout != "Text_Ad_with_Logo" &&
              adLayout != "Text_Ad_without_Logo" && (
                <Grid xs={12} sx={{ marginTop: "25px" }}>
                  <FormInput
                    control={control}
                    name="fileName"
                    disabled
                    type="text"
                    label={
                      adplacement == "INSTREAM" || adLayout.includes("Video")
                        ? " Video"
                        : "Image"
                    }
                  />
                </Grid>
              )}

            {adplacement == "OUTSTREAM" &&
              (adLayout == "Text_Ad_with_Logo" ||
                adLayout == "Text_Ad_without_Logo" ||
                adLayout.includes("Contextual")) && (
                <>
                  <Grid
                    xs={12}
                    data-testid="advertisement-title"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={control}
                      name="title"
                      disabled
                      rules={{ required: "Ad Title is required" }}
                      label="Ad Title"
                      type="text"
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    data-testid="advertisement-description"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={control}
                      name="description"
                      disabled
                      label="Ad Description"
                      type="text"
                    />
                  </Grid>
                </>
              )}

            {adplacement == "FEED_POST" && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-introCopy"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    disabled
                    name="description"
                    label="Ad Intro Copy"
                    type="text"
                  />
                </Grid>
              </>
            )}
            {adplacement != "" &&
              (adplacement !== "FEED_POST" || adLayout.includes("Link")) && (
                <Grid
                  xs={12}
                  data-testid="advertisement-redirect"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="redirect"
                    label="Ad Redirect Link"
                    type="text"
                    disabled
                  />
                </Grid>
              )}
            {adplacement == "FEED_POST" && adLayout.includes("Link") && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-callToActionTitle"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="title"
                    disabled
                    required
                    rules={{
                      required: "Call To Action Title is required"
                    }}
                    label="Call To Action Title"
                    type="text"
                  />
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-linkText"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="linkText"
                    disabled
                    label="Call To Action URL Text"
                    type="text"
                  />
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-actionButton"
                  marginTop={"25px"}
                >
                  <FormSelect
                    control={control}
                    name="actionButton"
                    disabled
                    options={CALL_TO_ACTION_OPTIONS}
                    label="Call To Action Button"
                  />
                </Grid>
              </>
            )}
            <Grid
              xs={12}
              data-testid="advertisement-status"
              marginTop={"25px"}
              marginBottom={"25px"}
            >
              <FormSelect
                control={control}
                name="status"
                label="Status"
                disabled
                options={statusoptions}
              />
            </Grid>
          </StyledGrid>
          <StyledLivePreview xs={12}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              Live Preview
            </Typography>{" "}
            <LivePreview
              layout={adLayout}
              placement={adplacement}
              adDetails={adDetails}
            />
          </StyledLivePreview>
        </StyledForm>
        <Grid xs={12} sx={{ margin: "0 20px" }}>
          <Typography
            style={{
              color: "#000",
              opacity: "0.5",
              letterSpacing: "1.2px"
            }}
          >
            ADVERTISER FEEDBACK
          </Typography>
          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid sx={{ marginTop: "24px", paddingLeft: "15px" }}>
          <RenderTableView
            title=""
            rows={advertisement?.data?.feedbacks || []}
            columns={ADVERTISEMENT_FEEDBACK_COLUMNS}
            hideFilter
            getRowId={(row) => row?.feedbackId}
            searchable={false}
            hideToolbar={true}
            hideFooter={true}
            getRowHeight={() => 150}
            pinnedColumns={{ left: ["action", "advertisement"] }}
            customActions={[
              (params) =>
                !params.row.isApproved &&
                params.row.orgSentById != loggedInUser &&
                !params.row.replyOf && (
                  <IconButton onClick={() => reply(params.row.feedbackId)}>
                    <ReplyIcon />
                  </IconButton>
                )
            ]}
          />
        </Grid>
      </Container>
      <ConfirmationDialog
        open={advertisementToDelete}
        title="Delete Advertisement?"
        body={`Are you sure you want to delete this advertisement?`}
        close={() => setAdvertisementToDelete(false)}
        onConfirm={onConfirmDelete}
        onCancel={() => setAdvertisementToDelete(false)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Loader>
  );
};
