import { Container } from "@components/crud/Container";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "@utils/formatCurrency";
import PrintIcon from "@assets/icons/printIcon.svg";
import DownloadIcon from "@assets/icons/downloadIcon.svg";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SGLogo from "@assets/images/sg-logo.png";
import PaymentConfirmation, {
  PaymentConfirmationDialogProps
} from "./PaymentConfirmation";
import { PaymentDetailsForm } from "../../components/PaymentDetailsForm";
import { InvoicePDF, InvoicePDFDetailsProps } from "@components/InvoicePDF";
import {
  AdvertiserInvoiceSchema,
  AdvertiserInvoiceSchemaPaymentType,
  AdvertiserInvoiceSchemaStatus,
  MerchantGetResponse,
  ModelMedia,
  useAdminAdvertiserInvoiceInvoiceIdEmailGet,
  useAdminAdvertiserInvoiceInvoiceIdTaxExemptPost,
  useAdminMerchantGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { usePDF } from "@react-pdf/renderer";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import {
  formatPhoneWithCountryCode,
  formatPhoneWithoutCountryCode
} from "@utils/phoneFormatters";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VisaCard from "@assets/icons/Visa.svg";
import MasterCard from "@assets/icons/Mastercard.svg";
import DiscoverCard from "@assets/icons/Discover.svg";
import AmericanExpress from "@assets/icons/Amex.svg";
import Grid from "@mui/system/Unstable_Grid";
import { StepProgressLine } from "@components/StepProgressLine";
import { Footer } from "@components/crud/Footer";
import { TaxExemptForm } from "./TaxExemptForm";
import { FormProvider, useForm } from "react-hook-form";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";
import { uploadMediaUsingUploadParams } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";
import GoogleMap from "@components/GoogleMap";

const Header = styled("div")`
  background-color: #f5f5f5;
  padding: 8px 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0px 1px 3px 0px #00000026;
  .MuiAvatar-root {
    height: 48px;
    width: 48px;
  }
  @media (max-width: 576px) {
    padding: 8px 15px;
    .MuiAvatar-root {
      height: 32px;
      width: 32px;
    }
  }
`;

const Body = styled("div")`
  padding: 32px;
  margin-top: 3px;
  width: 100%;
  background-color: #f3f4f7;
  min-height: calc(100vh - 64px);
  .payment-details {
    flex-direction: row;
  }
  .invoice-details {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  @media (max-width: 576px) {
    padding: 24px;
  }
  @media (max-width: 1000px) {
    .payment-details {
      flex-direction: column;
      align-items: center;
    }
    .invoice-details {
      flex-direction: row;
      max-width: 650px;
    }
  }
  @media (max-width: 767px) {
    .invoice-details {
      flex-direction: column;
      width: 100%;
      div {
        width: 100%;
      }
    }
  }
`;

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 305px;
  padding: 12px 16px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
`;

const TitleText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
`;

const DescriptionText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 20px;
`;

const StyledDivider = styled(Divider)`
  height: 4px;
  border-bottom-width: medium;
  border-color: #f3f4f7;
  margin-left: -16px;
  width: calc(100% + 32px);
`;

const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

const formatDate = (isoDate: string | Date | undefined): string => {
  if (!isoDate) return "";
  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const steps = ["Tax Exempt Form", "Payment Details"];

const InvoicePayment = () => {
  const navigate = useNavigate();
  const { invoiceId, email, advertiserId } = useParams();
  const [addressToView, setAddressToView] = useState<string | undefined>();
  const [file, setFile] = useState<File | ModelMedia | undefined>(undefined);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [invoiceInstance, setInvoiceInstance] = usePDF();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [invoiceInstanceProps, setInvoiceInstanceProps] = useState<
    InvoicePDFDetailsProps | undefined
  >(undefined);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<
    PaymentConfirmationDialogProps | undefined
  >(undefined);
  const [invoiceDetails, setInvoiceDetails] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [merchantData, setMerchantData] = useState<MerchantGetResponse | null>(
    null
  );
  const [tab, setTab] = useState("Tax Exempt Form");
  const activeStepNumber = steps.findIndex((step) => step === tab);
  const form = useForm({ mode: "onTouched" });

  const {
    data: advertiserInvoice,
    isLoading: isLoading,
    error: invoiceError
  } = useAdminAdvertiserInvoiceInvoiceIdEmailGet(
    advertiserId!,
    invoiceId!,
    email!
  );

  const { mutate: uploadTaxForm, isLoading: isUploading } =
    useAdminAdvertiserInvoiceInvoiceIdTaxExemptPost();

  useEffect(() => {
    if (invoiceError) navigate("/not-found");
  }, [invoiceError]);

  const { data: merchant, isLoading: isMerchantLoading } = useAdminMerchantGet(
    advertiserInvoice?.data.advertiser?.organization?.merchantId as string,
    {
      query: {
        enabled: !!advertiserInvoice?.data.advertiser?.organization?.merchantId
      }
    }
  );

  useEffect(() => {
    if (advertiserInvoice?.data) {
      setInvoiceDetails(advertiserInvoice?.data);
      const advertiserInvoiceData = advertiserInvoice?.data;
      const props: InvoicePDFDetailsProps = {
        from: {
          name: advertiserInvoiceData.advertiser?.organization?.name as string,
          address:
            advertiserInvoiceData.advertiser?.organization?.lines?.[0] +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.locality +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.province +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.country
        },
        invoiceNumber: advertiserInvoiceData.invoiceId as string,
        orderDate: advertiserInvoiceData.createdAt as Date,
        paymentDate: advertiserInvoiceData?.paidAt,
        invoiceDate: advertiserInvoiceData.createdAt as Date,
        startDate: advertiserInvoiceData.campaign?.startDate as Date,
        endDate: advertiserInvoiceData.campaign?.endDate as Date,
        paymentMode: advertiserInvoiceData.paymentType
          ? `${
              CRM_ORDER_PAYMENT_TYPES.find(
                (c) => c.value === advertiserInvoiceData.paymentType
              )?.label
            } - ${advertiserInvoiceData?.transactionInfo?.payment?.number}`
          : "",
        terms: "Due Upon Receipt",
        paymentStatus:
          advertiserInvoiceData.status as AdvertiserInvoiceSchemaStatus,
        billTo: {
          to: advertiserInvoiceData.advertiser?.businessName as string,
          name:
            advertiserInvoiceData.campaign?.billingContact?.firstName +
            " " +
            advertiserInvoiceData.campaign?.billingContact?.lastName,
          email:
            advertiserInvoiceData?.campaign?.billingContact?.accounts?.find(
              (a) =>
                a.accountId === advertiserInvoiceData?.advertiser?.accountId
            )?.workEmail ?? "",
          phone: formatPhoneWithCountryCode(
            advertiserInvoiceData?.campaign?.billingContact?.accounts?.find(
              (a) =>
                a.accountId === advertiserInvoiceData?.advertiser?.accountId
            )?.workPhone ??
              advertiserInvoiceData?.campaign?.billingContact?.phone
          )
        },
        items: [
          {
            name: `${advertiserInvoiceData.campaign?.sponsorshipLevel?.name} Sponsorship`,
            amount: advertiserInvoiceData.amountPreTax as number,
            unitPrice: advertiserInvoiceData.amountPreTax as number,
            quantity: 1,
            desciption: [
              {
                name: "Ad Placements",
                subDescription: [
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.sponsoredByEnabled && {
                    name: "Sponsored By Ad - Live Stream Event Feed Posts",
                    isBold: true
                  },
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.outStreamEnabled && {
                    name: "Out-Stream Ad - View Live Stream Screen",
                    isBold: true
                  },
                  advertiserInvoiceData.campaign?.sponsorshipLevel
                    ?.inStreamEnabled && {
                    name: "In-Stream Ad - Live Streaming Intermissions",
                    isBold: true
                  }
                ].filter(Boolean)
              },
              {
                name: `Ads will start when this invoice is paid and will run for 1 year`
              },
              {
                name: "Unlimited Impressions"
              }
            ]
          }
        ],
        taxDetails: {
          taxAmount: advertiserInvoiceData?.taxAmount ?? 0,
          taxRate: advertiserInvoiceData?.taxRate ?? 0
        },
        feeDetails: {
          feeAmount: advertiserInvoiceData?.feeAmount ?? 0,
          feeRate: advertiserInvoiceData?.feeRate ?? 0
        }
      };
      setInvoiceInstanceProps(props);
      setInvoiceInstance(
        <InvoicePDF
          invoiceFor="ADVERTISER"
          hasFooter={true}
          hasHeader={true}
          invoiceDetails={props}
          showSGLogo={false}
        />
      );
      if (advertiserInvoice.data.status === "PAID") {
        setConfirmationDialogProps({
          organization: {
            name: advertiserInvoiceData?.advertiser?.organization
              ?.name as string,
            phone: formatPhoneWithCountryCode(
              advertiserInvoiceData?.advertiser?.organization?.phone as string
            ),
            address:
              advertiserInvoiceData?.advertiser?.organization?.lines?.[0] +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.locality +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.province +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.country,
            email: advertiserInvoiceData?.advertiser?.organization
              ?.email as string
          },
          paymentDetails: {
            type: advertiserInvoiceData.paymentType as AdvertiserInvoiceSchemaPaymentType,
            number: advertiserInvoiceData?.transactionInfo?.payment
              ?.number as string,
            transactionId: advertiserInvoiceData.transactionId as string
          },
          paymentDate: advertiserInvoiceData?.paidAt,
          amount: advertiserInvoiceData?.amountPreTax || 0,
          total:
            (advertiserInvoiceData?.amountPreTax || 0) +
            (advertiserInvoiceData?.taxAmount || 0) +
            (advertiserInvoiceData.feeAmount || 0),
          invoiceId: advertiserInvoiceData.invoiceId as string,
          to: advertiserInvoiceData?.advertiser?.organization?.name as string
        });
        setPaymentSuccess(true);
      }
      if (advertiserInvoiceData?.campaign?.taxExemptFormId)
        setFile(advertiserInvoiceData.campaign.taxExemptForm);

      if (!advertiserInvoiceData.campaign?.isTaxExempt)
        setTab("Payment Details");

      if (
        advertiserInvoiceData.campaign?.isTaxExempt &&
        advertiserInvoiceData.campaign.taxExemptFormId
      ) {
        setTab("Payment Details");
      }
    }
  }, [advertiserInvoice]);

  useEffect(() => {
    if (merchant?.data) setMerchantData(merchant?.data);
  }, [merchant]);

  useEffect(() => {
    if (invoiceInstanceProps)
      setInvoiceInstance(
        <InvoicePDF
          invoiceFor="ADVERTISER"
          hasFooter={true}
          hasHeader={true}
          invoiceDetails={invoiceInstanceProps}
          showSGLogo={false}
        />
      );
  }, [invoiceInstanceProps]);

  const onUploadForm = async () => {
    if (file instanceof File) {
      uploadTaxForm(
        {
          advertiserId: advertiserId!,
          invoiceId: invoiceId!,
          email: email!
        },
        {
          onSuccess: async (data) => {
            setIsUploadingMedia(true);
            try {
              await uploadMediaUsingUploadParams(file, data.data.preSignedPost);
              enqueueSnackbar("Tax Exempt Form uploaded successfully!", {
                variant: "success"
              });
              setIsUploadingMedia(false);
              setTab("Payment Details");
            } catch (e) {
              setIsUploadingMedia(false);
              enqueueSnackbar("Failed to upload Tax Exempt Form!", {
                variant: "error"
              });
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to upload Tax Exempt Form!", {
              variant: "error"
            });
          }
        }
      );
    } else setTab("Payment Details");
  };

  const openMailClient = (email: string | undefined) => {
    if (!email) return;
    navigator.clipboard.writeText(email);
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };

  const openCallClient = (phone: string | undefined) => {
    if (!phone) return;
    navigator.clipboard.writeText(phone);
    const telUrl = `tel:${phone}`;
    window.location.href
      ? window.open(telUrl, "_blank")?.focus()
      : window.location.replace(telUrl);
  };

  if (isLoading || isMerchantLoading) {
    return (
      <StyledSGLogoLoader>
        <img src={SGLogoLoader} />
      </StyledSGLogoLoader>
    );
  }

  return (
    <>
      <Container>
        <Header>
          <Avatar
            variant="square"
            src={
              invoiceDetails?.advertiser?.organization?.avatar?.baseUrl &&
              invoiceDetails?.advertiser?.organization?.avatar?.path
                ? invoiceDetails?.advertiser?.organization?.avatar?.baseUrl +
                  invoiceDetails?.advertiser?.organization?.avatar?.path
                : ""
            }
          />
          <HeaderText>
            {invoiceDetails?.advertiser?.organization?.name}
          </HeaderText>
        </Header>

        {invoiceDetails?.campaign?.isTaxExempt &&
          invoiceDetails.status !== "PAID" && (
            <Box
              sx={{
                padding: { sm: "24px 15px", md: "32px" }
              }}
            >
              <Grid
                xs={12}
                sm={6}
                sx={{
                  maxWidth: { sm: "100%", md: "50%" },
                  margin: "auto"
                }}
              >
                <StepProgressLine
                  steps={["Tax Exempt Form", "Payment Details"]}
                  activeStepNumber={activeStepNumber}
                  onEditClick={(active) => {
                    setTab(steps[active]);
                  }}
                />
              </Grid>
            </Box>
          )}
        <Body
          sx={{
            minHeight:
              !invoiceDetails?.campaign?.isTaxExempt || paymentSuccess
                ? "calc(100vh - 64px)"
                : "calc(100vh - 237px)"
          }}
          style={{
            ...(tab === "Tax Exempt Form"
              ? { paddingLeft: "0px", paddingRight: "0px" }
              : {})
          }}
        >
          <FormProvider {...form}>
            {tab === "Tax Exempt Form" && (
              <TaxExemptForm file={file} setFile={setFile} />
            )}
            {!paymentSuccess && tab === "Payment Details" ? (
              <FlexBox
                style={{ justifyContent: "center", gap: "24px" }}
                className="payment-details"
              >
                <PaymentDetailsForm
                  details={{
                    amount:
                      (invoiceDetails?.amountPreTax || 0) +
                      (invoiceDetails?.taxAmount || 0) +
                      (invoiceDetails?.feeAmount || 0),
                    merchantId: advertiserInvoice?.data.advertiser?.organization
                      ?.merchantId as string,
                    invoiceId: advertiserInvoice?.data.invoiceId as string,
                    compressedEmail: email!,
                    advertiserId: advertiserId!,
                    merchantName:
                      advertiserInvoice?.data.advertiser?.organization?.name
                  }}
                  txnType="TRANSACTION"
                  paymentFor="INVOICE"
                />
                <FlexBox className="invoice-details">
                  <StyledDiv>
                    <FlexBox>
                      <DescriptionText>Invoice</DescriptionText>
                      <TitleText>{invoiceDetails?.invoiceId}</TitleText>
                    </FlexBox>
                    <FlexBox>
                      <DescriptionText>Due Date</DescriptionText>
                      <TitleText>
                        {" "}
                        {invoiceDetails?.sentAt
                          ? formatDate(new Date(invoiceDetails.sentAt))
                          : ""}
                      </TitleText>
                    </FlexBox>
                    <FlexBox>
                      <DescriptionText>Invoice Amount</DescriptionText>
                      <TitleText>
                        {formatCurrency(invoiceDetails?.amountPreTax as number)}{" "}
                        USD
                      </TitleText>
                    </FlexBox>

                    <FlexBox>
                      <DescriptionText>Status</DescriptionText>
                      <TitleText
                        style={{
                          borderRadius: "20px",
                          padding: "4px 8px",
                          backgroundColor:
                            invoiceDetails?.status !== "PAID"
                              ? "#FFEEEE"
                              : "#BBF7D0",
                          color:
                            invoiceDetails?.status !== "PAID"
                              ? "#A11212"
                              : "#1ABC9C",
                          fontSize: "12px",
                          lineHeight: "15px"
                        }}
                      >
                        {invoiceDetails?.status === "PAID" ? "Paid" : "Unpaid"}
                      </TitleText>
                    </FlexBox>
                    <StyledDivider />
                    <FlexBox>
                      <TitleText>Total</TitleText>
                      <TitleText>
                        {formatCurrency(
                          (invoiceDetails?.amountPreTax || 0) +
                            (invoiceDetails?.taxAmount || 0) +
                            (invoiceDetails?.feeAmount || 0)
                        )}{" "}
                        USD
                      </TitleText>
                    </FlexBox>
                    <StyledDivider />

                    <FlexBox style={{ alignItems: "center" }}>
                      <DescriptionText
                        style={{
                          color: "#007AFF",
                          fontWeight: 500,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (invoiceInstance?.url) {
                            window.open(invoiceInstance.url, "_blank");
                          }
                        }}
                      >
                        View Invoice
                      </DescriptionText>
                      <TitleText>
                        <FlexBox>
                          <IconButton
                            onClick={() =>
                              downloadPDFFromLink(
                                invoiceInstance.url,
                                `${invoiceDetails?.invoiceId}.pdf`
                              )
                            }
                          >
                            <img src={DownloadIcon} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (invoiceInstance?.url) {
                                const newWindow = window.open(
                                  invoiceInstance.url,
                                  "_blank"
                                );
                                if (newWindow) {
                                  newWindow.onload = () => {
                                    setTimeout(() => {
                                      newWindow.print();
                                    }, 50);
                                  };
                                }
                              }
                            }}
                          >
                            <img src={PrintIcon} />
                          </IconButton>
                        </FlexBox>
                      </TitleText>
                    </FlexBox>
                  </StyledDiv>
                  <StyledDiv style={{ gap: "8px" }}>
                    <FlexBox>
                      <TitleText>Merchant Details</TitleText>
                    </FlexBox>
                    <FlexBox
                      style={{
                        justifyContent: "flex-start",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        openMailClient(merchantData?.merchant?.entity?.email)
                      }
                    >
                      <EmailOutlinedIcon style={{ color: "#666666" }} />
                      <DescriptionText
                        style={{ fontWeight: 500, color: "#007AFF" }}
                      >
                        {merchantData?.merchant?.entity?.email}
                      </DescriptionText>
                    </FlexBox>
                    {merchantData?.merchant?.entity?.phone && (
                      <FlexBox
                        style={{
                          justifyContent: "flex-start",
                          gap: "8px",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          openCallClient(merchantData?.merchant?.entity?.phone)
                        }
                      >
                        <PermContactCalendarOutlinedIcon
                          style={{ color: "#666666" }}
                        />
                        <DescriptionText
                          style={{
                            color: "#007AFF",
                            fontWeight: 500
                          }}
                        >
                          +1{" "}
                          {formatPhoneWithoutCountryCode(
                            merchantData?.merchant?.entity?.phone
                          )}
                        </DescriptionText>
                      </FlexBox>
                    )}
                    <FlexBox
                      style={{
                        justifyContent: "flex-start",
                        gap: "8px",
                        alignItems: "center"
                      }}
                    >
                      <RoomOutlinedIcon style={{ color: "#666666" }} />
                      <DescriptionText
                        style={{
                          color: "#007AFF",
                          fontWeight: 500,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          setAddressToView(
                            merchantData?.merchant?.entity?.address1
                          )
                        }
                      >
                        {merchantData?.merchant?.entity?.address1},{" "}
                        {merchantData?.merchant?.entity?.city},{" "}
                        {merchantData?.merchant?.entity?.state},{" "}
                        {merchantData?.merchant?.entity?.country}
                      </DescriptionText>
                    </FlexBox>
                  </StyledDiv>
                  <FlexBox
                    style={{
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <div
                      style={{
                        color: "#666666",
                        fontSize: "12px",
                        width: "fit-content"
                      }}
                    >
                      Available Payment Methods
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        width: "fit-content"
                      }}
                    >
                      <img src={VisaCard} />
                      <img src={MasterCard} />
                      <img src={DiscoverCard} />
                      <img src={AmericanExpress} />
                    </div>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            ) : (
              <FlexBox
                style={{ justifyContent: "center", gap: "24px" }}
                className="payment-details"
              >
                <PaymentConfirmation
                  confirmationDetails={
                    confirmationDialogProps as PaymentConfirmationDialogProps
                  }
                  invoiceInstance={invoiceInstance}
                  confirmationType="SUCCESS"
                />
              </FlexBox>
            )}
          </FormProvider>
          <FlexBox style={{ justifyContent: "center", marginTop: "32px" }}>
            <FlexBox style={{ alignItems: "center", gap: "10px" }}>
              <span>Powered by</span>
              <img src={SGLogo} style={{ width: "130px", height: "32px" }} />
            </FlexBox>
          </FlexBox>
        </Body>
        {tab === "Tax Exempt Form" && (
          <Footer
            saveBtnClick={onUploadForm}
            isDisabled={!file || isUploading || isUploadingMedia}
            isLoading={isUploading || isUploadingMedia}
            saveBtnLabel="Next"
          />
        )}
        {addressToView && (
          <GoogleMap
            close={() => setAddressToView(undefined)}
            address={addressToView}
            showSearchBar={false}
            showClickedAddress={false}
            allowMarkerDrag={false}
          />
        )}
      </Container>
    </>
  );
};

export default InvoicePayment;
