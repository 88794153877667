import { styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React, { useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DebitCardIcon from "@assets/icons/debitCardIcon.svg";
import CreditCardIcon from "@assets/icons/creditCardIcon.svg";
import { useForm } from "react-hook-form";
import { formatCurrency } from "@utils/formatCurrency";
import { AdvertiserInvoiceSchemaPaymentType } from "@sportsgravyengineering/sg-api-react-sdk";

const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 100%;
  max-width: 650px;
  padding: 24px;
  @media (max-width: 576px) {
    padding: 16px;
  }
`;

const StyledPaymentType = styled(Grid)`
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  border: 1.5px solid #e5e5e5;
  height: 48px;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 10px 12px;
  }
`;

const StyledPaymentTypeName = styled("div")`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

export const PaymentDetailsForm = ({
  details,
  setPaymentType,
  txnType = "TRANSACTION",
  paymentFor
}: {
  details: {
    amount?: number;
    merchantId: string;
    advertiserId?: string;
    invoiceId?: string;
    compressedEmail?: string;
    orderId?: string;
    merchantName?: string;
  };
  setPaymentType?: (val: AdvertiserInvoiceSchemaPaymentType) => void;
  txnType?: "TRANSACTION" | "TOKENIZATION";
  paymentFor: "INVOICE" | "ORDER" | "ADD_PAYMENT";
}) => {
  const hostname = window.location.hostname;
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<AdvertiserInvoiceSchemaPaymentType>("DEBIT_CARD");
  const { reset } = useForm({
    mode: "onTouched",
    defaultValues: {
      cvv: "",
      number: "",
      expirationDate: null,
      cardName: "",
      country: "",
      zipCode: "",
      billingInstructions: "",
      routingNumber: "",
      accountType: "",
      accountName: "",
      acceptTnC: false
    }
  });

  return (
    <StyledDiv>
      {details?.amount && (
        <div style={{ marginBottom: "24px" }}>
          <Typography style={{ fontWeight: 400, color: "#B3B3B3" }}>
            Payment Amount
          </Typography>
          <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
            {formatCurrency(details?.amount)} USD
          </Typography>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("DEBIT_CARD");
              if (setPaymentType) setPaymentType("DEBIT_CARD");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "DEBIT_CARD"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <img src={DebitCardIcon} />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "DEBIT_CARD" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "DEBIT_CARD" ? "600" : "500"
            }}
          >
            Debit Card
          </StyledPaymentTypeName>
        </Grid>
        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("CREDIT_CARD");
              if (setPaymentType) setPaymentType("CREDIT_CARD");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "CREDIT_CARD"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <img src={CreditCardIcon} />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "CREDIT_CARD" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "CREDIT_CARD" ? "600" : "500"
            }}
          >
            Credit Card
          </StyledPaymentTypeName>
        </Grid>

        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("BANK_ACH");
              if (setPaymentType) setPaymentType("BANK_ACH");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "BANK_ACH"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <AccountBalanceIcon />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "BANK_ACH" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "BANK_ACH" ? "600" : "500"
            }}
          >
            Bank (ACH)
          </StyledPaymentTypeName>
        </Grid>
      </Grid>
      <Grid container>
        <iframe
          src={`/${hostname === "app.sportsgravy.com" ? "payrix" : "payrix.dev"}.html?data=${encodeURIComponent(
            JSON.stringify({
              amount: details.amount,
              type: selectedPaymentType,
              merchantId: details.merchantId,
              invoiceId: details.invoiceId as string,
              txnType,
              compressedEmail: details.compressedEmail,
              advertiserId: details.advertiserId,
              paymentFor,
              orderId: details.orderId,
              merchantName: details.merchantName
            })
          )}`}
          width="100%"
          height={
            selectedPaymentType === "BANK_ACH"
              ? txnType === "TOKENIZATION"
                ? "750px"
                : "650px"
              : txnType === "TOKENIZATION"
                ? "950px"
                : "1040px"
          }
          title="Payment"
          style={{ border: "none" }}
        />
      </Grid>
    </StyledDiv>
  );
};
