/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import {
  AD_LAYOUT_OPTIONS,
  AD_PLACEMENT_OPTIONS,
  CALL_TO_ACTION_OPTIONS
} from "@utils/constants";
import { ChangeEvent, useEffect, useState } from "react";
import { FormInput } from "@components/FormInput";
import { Backdrop, FormHelperText, Typography } from "@mui/material";
import { LivePreview } from "@components/LivePreview";
import { styled } from "@mui/material";
import {
  AdminSettingGetObject,
  ModelConfig,
  ModelMedia,
  useAdminAdvertiserAdvertisementIdGet,
  useAdminAdvertiserIdAdvertisementIdPut,
  useAdminAdvertiserIdStatusPost,
  useAdminSettingsGet,
  useConfigGet,
  useLookupOgPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { WEBSITE_REGEX } from "@utils/validation";
const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column !important"
  }
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "50% !important",
  [theme.breakpoints.down("xl")]: {
    width: "100% !important"
  }
}));
const StyledLivePreview = styled(Grid)(({ theme }) => ({
  width: "500px !important",
  marginLeft: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "75% !important"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "100% !important"
  }
}));
export const AdvertisementEdit = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { advertiserId } = useParams();
  const { advertisementId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAdvertiserInactiveWarning, setOpenAdvertiserInactiveWarning] =
    useState(false);
  const { data: setting } = organizationId
    ? useAdminSettingsGet({
        organizationId: organizationId,
        parentId: "org-live-stream"
      })
    : useConfigGet();
  const [adplacement, setAdPlacement] = useState("");
  const [adLayout, setAdLayout] = useState("");
  const [metaData, setMetaData] = useState<unknown | null>({});
  const [adDetails, setAdDetails] = useState<{
    title: string;
    description: string;
    media: File | ModelMedia | null | undefined;
    icon: string | undefined;
    redirect: string | undefined;
    linkText: string;
    actionButton: string | undefined;
  }>({
    title: "",
    description: "",
    media: undefined,
    icon: undefined,
    redirect: undefined,
    linkText: "",
    actionButton: undefined
  });

  const [file, setFile] = useState<File | undefined>(undefined);
  const { data: advertisement, isLoading: advertisementLoading } =
    useAdminAdvertiserAdvertisementIdGet(advertiserId!, advertisementId!);
  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, dirtyFields },
    reset,
    setValue,
    clearErrors
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      placement: "",
      layout: "",
      status: "",
      duration: "",
      file: undefined,
      title: "",
      description: "",
      redirectLink: "",
      icon: undefined,
      linkText: "",
      actionButton: ""
    }
  });
  useEffect(() => {
    if (advertisement?.data) {
      reset({
        placement: advertisement.data.placement,
        status: advertisement.data.status,
        layout: advertisement.data.layout || "",
        file: advertisement.data.media?.baseUrl
          ? advertisement.data.media.baseUrl + advertisement.data.media.path
          : advertisement.data.layout == "Link" &&
              advertisement.data?.metadata?.image
            ? advertisement.data?.metadata?.image
            : undefined,
        title: advertisement.data.title || "",
        description: advertisement.data.description || "",
        redirectLink: advertisement.data.redirectLink || "",
        icon: undefined,
        linkText: advertisement.data.linkText || "",
        actionButton: advertisement.data.actionButton || undefined
      });
      if (advertisement.data.placement == "INSTREAM") {
        setValue("duration", String(advertisement?.data?.duration as number));
      }
      setAdDetails((details) => ({
        ...details,
        title: advertisement.data.title || "",
        description: advertisement.data.description || "",
        media:
          advertisement.data.layout == "Link" &&
          advertisement.data?.metadata?.image
            ? advertisement.data?.metadata?.image
            : advertisement.data.media?.baseUrl
              ? advertisement.data.media.baseUrl + advertisement.data.media.path
              : "",
        logo: advertisement.data.advertiser?.logo?.baseUrl
          ? advertisement.data.advertiser?.logo?.baseUrl +
            advertisement.data.advertiser?.logo?.path
          : "",
        redirect: advertisement.data.redirectLink || "",
        actionButton: advertisement.data.actionButton || "",
        businessName: advertisement.data.advertiser?.businessName || "",
        linkText: advertisement.data.linkText || ""
      }));
      setAdPlacement(advertisement.data.placement as string);
      setAdLayout(advertisement.data.layout || "");
    }
  }, [advertisement]);
  const { mutate: changeStatus } = useAdminAdvertiserIdStatusPost();
  const changeStatusHandler = async () => {
    try {
      await changeStatus({
        advertiserId: advertiserId!,
        data: {
          status: "ACTIVE"
        }
      });
      enqueueSnackbar("Advertiser status changed successfully!", {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar("Failed to change advertiser status!", {
        variant: "error"
      });
    }
  };
  const { mutate: lookup } = useLookupOgPost();
  const lookupHandler = async () => {
    if (adDetails.redirect && adDetails.redirect !== "") {
      lookup(
        {
          data: {
            url: adDetails.redirect
          }
        },
        {
          onSuccess: (data) => {
            setMetaData(data.data);
          }
        }
      );
    }
  };
  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiserIdAdvertisementIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      const values = {
        ...formValues
      };
      delete values["file"];
      if (values["placement"] != "INSTREAM") {
        delete values["duration"];
      }
      if (values["placement"] == "INSTREAM") {
        values["layout"] = "";
        values["title"] = "";
        values["description"] = "";
        values["duration"] = Number(values["duration"]);
      }
      const adProperties = AD_LAYOUT_OPTIONS.find(
        (layout) => layout.value === values["layout"]
      );
      if (!adProperties?.fields.includes("title")) {
        delete values["title"];
      }
      if (!adProperties?.fields.includes("description")) {
        delete values["description"];
      }
      if (
        !adProperties?.fields.includes("image") &&
        !adProperties?.fields.includes("video") &&
        values["placement"] != "INSTREAM"
      ) {
        values["mediaId"] = null;
      }
      //@ts-ignore
      if (file && !file.mediaId) {
        setLoading(true);
        const promise = await uploadMediaUsingPresignedUrl(file);
        values["mediaId"] = promise;
      }

      if (
        !Object.prototype.hasOwnProperty.call(dirtyFields, "status") ||
        Object.keys(dirtyFields).length > 1
      ) {
        if (organizationId) {
          if (
            (setting?.data as AdminSettingGetObject[]).find(
              (s) =>
                s.settingId === "org-live-stream.advertiser-approval-required"
            )?.value
          ) {
            values["isApproved"] = false;
            values["status"] = "INACTIVE";
          }
        } else {
          if (
            (setting?.data as ModelConfig[]).find(
              (s) => s.key === "live-stream.advertiser-approval-required"
            )?.value
          ) {
            values["isApproved"] = false;
            values["status"] = "INACTIVE";
          }
        }
      }
      if (
        advertisement?.data.advertiser?.status == "INACTIVE" &&
        values["status"] == "ACTIVE"
      ) {
        changeStatusHandler();
      }

      try {
        save(
          {
            advertiserId: advertiserId!,
            advertisementId: advertisementId!,
            data: values
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar("Advertisement edit successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
                //@ts-ignore
                setFile(null);
                //@ts-ignore
                if (inputFileRef.current) {
                  //@ts-ignore
                  inputFileRef.current.value = "";
                }
              } else {
                navigate(
                  `/advertisers/${advertiserId}/advertisement/${advertisementId}`
                );
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar("Failed to edit advertisement!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };

  const onImageChange = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setFile(undefined);
      setAdDetails((details) => ({
        ...details,
        media: undefined
      }));
      field.onChange({ target: { value: file } });
      return;
    }
    setFile(file);
    field.onChange({ target: { value: file } });
    setAdDetails((details) => ({
      ...details,
      media: file
    }));
  };
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const resetFile = () => {
    setFile(undefined);
    setAdDetails((details) => ({
      ...details,
      media: undefined
    }));
    setValue("file", undefined);
  };

  return (
    <Loader isLoading={advertisementLoading}>
      <Container>
        <Toolbar title="Edit Advertisement" />
        <StyledForm style={{ flexDirection: "row" }}>
          <StyledGrid
            data-testid="advertisement-edit-form"
            container
            direction={"column"}
          >
            <Grid xs={12} data-testid="advertisement-placment">
              <FormSelect
                control={control}
                name="placement"
                label="Ad Placement"
                options={
                  organizationId
                    ? AD_PLACEMENT_OPTIONS.filter(
                        (option) => option.value !== "FEED_POST"
                      )
                    : AD_PLACEMENT_OPTIONS
                }
                required={true}
                rules={{
                  required: "Ad Placement is required"
                }}
                onChange={(e) => {
                  setAdPlacement(e.target.value);
                  setAdLayout("");
                  setValue("layout", "");
                  clearErrors();
                  resetFile();
                }}
              />
            </Grid>
            {adplacement !== "INSTREAM" && adplacement != "" && (
              <Grid
                xs={12}
                data-testid="advertisement-layout"
                marginTop={"25px"}
              >
                <FormSelect
                  control={control}
                  name="layout"
                  label="Ad Layout"
                  required={true}
                  options={
                    AD_LAYOUT_OPTIONS.filter((option) =>
                      option.adType.includes(adplacement)
                    ) || []
                  }
                  rules={{
                    required: "Ad Layout is required"
                  }}
                  onChange={(e) => {
                    const newLayout = e.target.value;
                    setMetaData(null);
                    if (
                      adLayout.includes("Video") &&
                      !newLayout.includes("Video")
                    )
                      resetFile();

                    if (
                      adLayout.includes("Photo") &&
                      !newLayout.includes("Photo")
                    )
                      resetFile();
                    setAdLayout(e.target.value);
                  }}
                />
              </Grid>
            )}
            {adplacement == "INSTREAM" && (
              <Grid
                xs={12}
                data-testid="advertisement-duration"
                marginTop={"25px"}
              >
                <FormSelect
                  label="Duration"
                  control={control}
                  name="duration"
                  required={true}
                  rules={{ required: "Duration is required" }}
                  options={[
                    { label: "6 seconds", value: "6" },
                    { label: "15 seconds", value: "15" },
                    { label: "30 seconds", value: "30" }
                  ]}
                />
              </Grid>
            )}
            {adplacement != "" &&
              (adLayout != "" || adplacement == "INSTREAM") &&
              adLayout != "Link" &&
              adLayout != "Text_Ad_with_Logo" &&
              adLayout != "Text_Ad_without_Logo" && (
                <Grid
                  xs={12}
                  data-testid="advertisement-media"
                  sx={{ marginTop: "25px" }}
                >
                  <FormInput
                    control={control}
                    name="file"
                    type="file"
                    label={
                      adplacement == "INSTREAM" || adLayout.includes("Video")
                        ? " Video"
                        : "Image"
                    }
                    required={true}
                    rules={{
                      required:
                        adplacement == "INSTREAM" || adLayout.includes("Video")
                          ? "Video is Required"
                          : "Image is Required"
                    }}
                    // @ts-ignore
                    onChange={onImageChange}
                    value={file}
                    InputProps={{
                      accept:
                        adplacement == "INSTREAM" || adLayout.includes("Video")
                          ? ".mp4"
                          : ".png, .jpeg, .jpg"
                    }}
                  />
                  <FormHelperText
                    style={{
                      color: "#B3B3B3",
                      fontSize: "13px",
                      marginLeft: "5px"
                    }}
                  >
                    Supported formats:{" "}
                    {adplacement == "INSTREAM" || adLayout.includes("Video")
                      ? "MP4"
                      : "JPG, JPEG, PNG"}
                  </FormHelperText>
                </Grid>
              )}
            {adplacement == "FEED_POST" && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-introCopy"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="description"
                    rules={{
                      maxLength: {
                        value: 150,
                        message: "Ad Intro Copy must be 150 characters or less"
                      },
                      required: false
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 150
                      }
                    }}
                    label="Ad Intro Copy"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        description: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {150 - adDetails.description.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
              </>
            )}
            {adplacement != "" &&
              (adplacement !== "FEED_POST" || adLayout.includes("Link")) && (
                <Grid
                  xs={12}
                  data-testid="advertisement-redirect"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="redirectLink"
                    label="Ad Redirect Link"
                    type="text"
                    required
                    rules={{
                      required: "Ad Redirect Link is required",
                      validate: (value) =>
                        value && value != "" && !WEBSITE_REGEX.test(value)
                          ? "Enter a valid link"
                          : undefined
                    }}
                    onChange={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>).target
                        .value;
                      if (value === "") setMetaData(null);
                      setAdDetails((details) => ({
                        ...details,
                        redirect: value
                      }));
                    }}
                    onBlur={() => {
                      if (adLayout == "Link") {
                        //@ts-ignore
                        setAdDetails((details) => ({
                          ...details,
                          media: ""
                        }));
                        lookupHandler();
                      }
                    }}
                  />
                </Grid>
              )}
            {adplacement == "OUTSTREAM" &&
              (adLayout == "Text_Ad_with_Logo" ||
                adLayout == "Text_Ad_without_Logo" ||
                adLayout.includes("Contextual")) && (
                <>
                  <Grid
                    xs={12}
                    data-testid="advertisement-title"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={control}
                      name="title"
                      required
                      TextProps={{
                        inputProps: {
                          maxLength: 40
                        }
                      }}
                      rules={{
                        required: "Ad Title is required",
                        maxLength: {
                          value: 40,
                          message: "Title must be 40 characters or less"
                        }
                      }}
                      label="Ad Title"
                      type="text"
                      onChange={(e) => {
                        setAdDetails((details) => ({
                          ...details,
                          //@ts-ignore
                          title: capitalizeFirstLetter(e.target.value)
                        }));
                      }}
                    />
                    <FormHelperText
                      style={{
                        fontSize: "12px",
                        textAlign: "right"
                      }}
                    >
                      <Typography
                        style={{ color: "#000", display: "inline" }}
                      ></Typography>{" "}
                      Characters Remaining:{" "}
                      <Typography
                        style={{
                          fontSize: "12px",
                          display: "inline",
                          color: "#000",
                          marginLeft: "10px"
                        }}
                      >
                        {40 - adDetails.title.length}
                      </Typography>
                    </FormHelperText>
                  </Grid>
                  <Grid
                    xs={12}
                    data-testid="advertisement-description"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={control}
                      name="description"
                      required
                      rules={{
                        required: "Ad Description is required",
                        maxLength: {
                          value: 100,
                          message: "Description must be 100 characters or less"
                        }
                      }}
                      TextProps={{
                        inputProps: {
                          maxLength: 100
                        }
                      }}
                      label="Ad Description"
                      type="text"
                      onChange={(e) => {
                        setAdDetails((details) => ({
                          ...details,
                          //@ts-ignore
                          description: e.target.value
                        }));
                      }}
                    />
                    <FormHelperText
                      style={{
                        fontSize: "12px",
                        textAlign: "right"
                      }}
                    >
                      <Typography
                        style={{ color: "#000", display: "inline" }}
                      ></Typography>{" "}
                      Characters Remaining:{" "}
                      <Typography
                        style={{
                          fontSize: "12px",
                          display: "inline",
                          color: "#000",
                          marginLeft: "10px"
                        }}
                      >
                        {100 - adDetails.description.length}
                      </Typography>
                    </FormHelperText>
                  </Grid>
                </>
              )}
            {adplacement == "FEED_POST" && adLayout.includes("Link") && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-callToActionTitle"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="title"
                    required
                    rules={{
                      required: "Call To Action Title is required",
                      maxLength: {
                        value: 30,
                        message: "Call To Action must be 30 characters or less"
                      }
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 30
                      }
                    }}
                    label="Call To Action Title"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        title: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {30 - adDetails.title.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-linkText"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={control}
                    name="linkText"
                    rules={{
                      maxLength: {
                        value: 30,
                        message:
                          "Call To Action Url text must be 30 characters or less"
                      }
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 30
                      }
                    }}
                    label="Call To Action URL Text"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        linkText: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {30 - adDetails.linkText.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-actionButton"
                  marginTop={"25px"}
                >
                  <FormSelect
                    control={control}
                    name="actionButton"
                    required
                    rules={{
                      required: "Call To Action Button is required"
                    }}
                    options={CALL_TO_ACTION_OPTIONS}
                    label="Call To Action Button"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        actionButton: e.target.value
                      }));
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid
              xs={12}
              data-testid="advertisement-status"
              marginTop={"25px"}
              marginBottom={"25px"}
            >
              <FormSelect
                control={control}
                required
                name="status"
                label="Status"
                options={statusoptions}
                disabled={
                  !advertisement?.data?.isApproved &&
                  advertisement?.data.status == "INACTIVE" &&
                  (organizationId
                    ? setting?.data
                        .filter(
                          //@ts-ignore
                          (s) =>
                            //@ts-ignore
                            s.settingId ===
                            "org-live-stream.advertiser-approval-required"
                        )[0]
                        //@ts-ignore
                        .organizationSettings?.filter(
                          (s) => s.organizationId === organizationId
                        ).length > 0
                      ? (setting?.data
                          .filter(
                            //@ts-ignore
                            (s) =>
                              //@ts-ignore
                              s.settingId ===
                              "org-live-stream.advertiser-approval-required"
                          )[0]
                          //@ts-ignore
                          .organizationSettings?.filter(
                            (s) => s.organizationId === organizationId
                          )[0].value as boolean)
                      : (setting?.data.filter(
                          //@ts-ignore
                          (s) =>
                            //@ts-ignore
                            s.settingId ===
                            "org-live-stream.advertiser-approval-required"
                        )[0].default as boolean)
                    : (setting &&
                        (setting.data?.find(
                          //@ts-ignore
                          (conf: ModelConfig) =>
                            conf.key ===
                            "live-stream.advertiser-approval-required"
                        )?.value as boolean)) ||
                      false)
                }
                rules={{
                  required: "Status is required"
                }}
                onChange={(e) => {
                  if (
                    advertisement?.data.advertiser?.status == "INACTIVE" &&
                    e.target.value === "ACTIVE"
                  ) {
                    setOpenAdvertiserInactiveWarning(true);
                  } else {
                    setOpenAdvertiserInactiveWarning(false);
                  }
                }}
              />
            </Grid>
          </StyledGrid>
          <StyledLivePreview xs={12}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              Live Preview
            </Typography>{" "}
            <LivePreview
              metaData={metaData}
              layout={adLayout}
              placement={adplacement}
              adDetails={adDetails}
            />
          </StyledLivePreview>
        </StyledForm>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving || loading || !isDirty}
          isLoading={isSaving || loading}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            navigate(`/advertisers/${advertiserId}`);
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
        <ConfirmationDialog
          open={openAdvertiserInactiveWarning}
          title="Active Advertiser Required"
          body={`To add an active advertisement for ${advertisement?.data.advertiser?.businessName} the advertiser status must be set to “Active”. Do you want to continue?`}
          close={() => setOpenAdvertiserInactiveWarning(false)}
          onConfirm={() => {
            setValue("status", "ACTIVE");
          }}
          onCancel={() => {
            setOpenAdvertiserInactiveWarning(false);
            setValue("status", "INACTIVE");
          }}
          isConfirming={false}
          confirmBtnVariant="admin-error"
          icon="error"
        />
        {loading && (
          <Backdrop
            sx={{
              overflow: "hidden",
              overflowY: "none",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
          >
            <LoadingSpinner />
          </Backdrop>
        )}
      </Container>
    </Loader>
  );
};
